import React from "react";
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import compose from 'recompose/compose';
import { ReducerType } from "../../redux/reducer.type";
import { LayoutAppPropsType } from "./layout-app.type";
import Footer from '../../components/layout/Footer';
import MenuLeft from "../../components/layout/MenuLeft";
import TopBar from "../../components/layout/TopBar";
import {isEmpty} from "../../utils/utils";

class AppLayout extends React.Component<LayoutAppPropsType, any> {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            children,
            settings: {
                isContentNoMaxWidth,
                isAppMaxWidth,
                isGrayBackground,
                isSquaredBorders,
                isCardShadow,
                isBorderless,
                isTopbarFixed,
                isGrayTopbar,
                menuLayoutType
            },
        } = this.props;
        let url = this.props.location.pathname.split("/");
        let check = false;
        if(url.length === 4){
            if(url[1] === 'lists' && url[2] === 'details' && this.props.listDetails && !isEmpty(this.props.listDetails) && this.props.listDetails.id === Number(url[3]) ) {
                check = true;
            }
        }
        return (
            <Layout
                className={classNames({
                    air__layout__contentNoMaxWidth: isContentNoMaxWidth,
                    air__layout__appMaxWidth: isAppMaxWidth,
                    air__layout__grayBackground: isGrayBackground,
                    air__layout__squaredBorders: isSquaredBorders,
                    air__layout__cardsShadow: isCardShadow,
                    air__layout__borderless: isBorderless,
                })}
            >
                {menuLayoutType === 'left' && <MenuLeft />}
                <Layout>
                    <Layout.Header
                        className={classNames('air__layout__header', {
                            air__layout__fixedHeader: isTopbarFixed,
                            air__layout__headerGray: isGrayTopbar,
                        })}
                    >
                        <TopBar />
                    </Layout.Header>
                    <Layout.Content style={{ height: '100%', minHeight:'700px',  position: 'relative' }}>
                        <div className={`air__utils__content ${check && "p-0 m-0"}`}>{children}</div>
                    </Layout.Content>
                    <Layout.Footer>
                        <Footer />
                    </Layout.Footer>
                </Layout>

            </Layout>
        );
    }
}

const mapStateToProps = (state: ReducerType) => ({
    settings: state.settings,
    listDetails: state.lists.listDetails
});

export default withRouter(compose<LayoutAppPropsType, any>(
    connect(mapStateToProps)
)(AppLayout)) as React.ComponentClass<{}>;
