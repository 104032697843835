export const authClientId = '1_2ifsdyv62qyog0oc8gs0s4cg8sw00o0kco4g0coww0gko4ww4k';
export const authClientSecret = '525fz52i7wwsskgg4owgc0cwo0skcko8s4cgw00wsgo48c8w0w';

export const baseUrlApi = 'http://www.api.buyme-shopping.com';

export const titlePrefix = 'BUYME';
export const titleDefult = 'Buyme';

export const languages = [
    {
        name: 'PL',
        i18n: 'pl'
    },
    {
        name: 'EN',
        i18n: 'en'
    }
];