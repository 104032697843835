import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from '../../index'
import { loginApi, getMeInfoApi } from '../../services/user.service';
import actions from './actions';
import actionsList from '../lists/actions';
import i18n from "i18next";
import {ROUTE_LISTS, ROUTE_LOGIN} from "../../config/routes";
import axios from 'axios';
import userActions from "../user/actions";

export function* LOGIN(action: {payload: any, type: string}) {
  const { payload } = action;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  try {
    const success = yield call(loginApi, payload.values);
    if (success.data) {
      if(payload.values.username === localStorage.getItem("userEmail")) {} else {
        yield localStorage.removeItem("sharedData");
        yield localStorage.removeItem("listsData");
        yield localStorage.removeItem("archiveData");
        yield localStorage.setItem('userEmail', payload.values.username);
      }

      yield localStorage.setItem('access_token', success.data.access_token);
      yield axios.defaults.headers.common['Authorization'] = `Bearer ${success.data.access_token}`;
      yield put({
        type: userActions.LOAD_CURRENT_ACCOUNT
      });
      yield put({
        type: actions.SET_STATE,
        payload: {
          authorized: true
        },
      });
      yield notification.success({
        message: i18n.t('logged_in_notification'),
        description: i18n.t('logged_in_notification_desc'),
      });
      yield history.push(ROUTE_LISTS);

  }
  } catch (e) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        authorized: false
      },
    });
    notification.error({
      message: i18n.t('logged_error_notification'),
      description: i18n.t('logged_error_notification_desc'),
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  try {
    const response = yield call(getMeInfoApi);
    if (response.data) {
      let { data } = response;
      let name = `${data.name} ${data.surname}`;
      yield localStorage.setItem('userName', name);
      yield put({
        type: actions.SET_STATE,
        payload: {
          ...data,
          authorized: true,
        },
      })
    }
  } catch (e) {

  }
}

export function* LOCAL_ERROR(action: {payload: string, type: string}) {
  yield history.push({
    pathname: ROUTE_LISTS,
    state: {pathName: action.payload}
  });

}

export function* GO_LOGIN(action: {payload: string, type: string}) {
  yield history.push({
    pathname: ROUTE_LOGIN,
    state: {pathName: action.payload}
  });
}


export function* LOCAL_LOGOUT(action: {payload: string, type: string}) {
  yield localStorage.removeItem('access_token');
  yield localStorage.removeItem('status');

  yield put({
    type: actionsList.SET_INIT_LISTS
  });
  yield history.push({
    pathname: ROUTE_LOGIN,
    state: {pathName: action.payload}
  });
  yield put({
    type: actions.SET_STATE,
    payload: {
      email: '',
      name: '',
      loading: false,
      authorized: false,
      city: '',
      imageName:'',
      status: "",
      surname: ""
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOCAL_LOGOUT, LOCAL_LOGOUT),
    takeEvery(actions.LOCAL_ERROR, LOCAL_ERROR),
    takeEvery(actions.GO_LOGIN, GO_LOGIN)
  ])
}
