import {
    ROUTE_ARCHIVE_LIST,
    ROUTE_LISTS, ROUTE_PROFILE, ROUTE_TEMPLATE,
} from "../config/routes";
import {
    faClipboardList, faArchive, faUserCircle, faListAlt
} from '@fortawesome/free-solid-svg-icons'

export default async function getMenuData() {
    if(localStorage.getItem("language") === 'pl'){
        return [
            {
                category: true,
                title: 'BuyMe',
            },
            {
                title: 'Listy zakupów',
                key: 'Lists',
                icon: faClipboardList,
                url: ROUTE_LISTS,
            },
            {
                title: 'Zarchiwizowane',
                key: 'Archive',
                icon: faArchive,
                url: ROUTE_ARCHIVE_LIST,
            },
            {
                title: 'Szablony',
                key: 'Template',
                icon: faListAlt,
                url: ROUTE_TEMPLATE,
            },
            {
                category: true,
                title: 'Konto użytkownika',
            },
            {
                title: 'Profil użytkownika',
                key: 'User profile',
                icon: faUserCircle,
                url: ROUTE_PROFILE,
            }

        ]
    } else {
        return [
            {
                category: true,
                title: 'BuyMe',
            },
            {
                title: 'Lists',
                key: 'Lists',
                icon: faClipboardList,
                url: ROUTE_LISTS,
            },
            {
                title: 'Archive',
                key: 'Archive',
                icon: faArchive,
                url: ROUTE_ARCHIVE_LIST,
            },
            {
                title: 'Template',
                key: 'Template',
                icon: faListAlt,
                url: ROUTE_TEMPLATE,
            },
            {
                category: true,
                title: 'User account',
            },
            {
                title: 'User profile',
                key: 'User profile',
                icon: faUserCircle,
                url: ROUTE_PROFILE,
            }
        ]
    }

}
