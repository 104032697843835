import axios from "axios"
import {
    AddNewProductInterface,
    AddNewTemplateInterface,
    AddProductToTemplateInterface, AddSharedListInterface, EditProductTemplateInterface, PutPermissionsInterface,
    SingleProductInterface
} from "../redux/lists/lists.type";


async function postSendEmailApi(email:string){
    return axios({
        method: "POST",
        url: ``,
        data: {
            email: email
        },
    })
}
async function getListsApi(){
    return axios({
        method: "GET",
        url: `/api/v2/lists`,
        params: {
            _page: 1,
            _page_limit: 9999999999999999999999999999,
            _type: 1
        },
    })
}

async function getSharedListsApi(){
    return axios({
        method: "GET",
        url: `/api/v2/lists`,
        params: {
            _page: 1,
            _page_limit: 9999999999999999999999999999,
            _type: 3
        },
    })
}


async function getArchiveListsApi(){
    return axios({
        method: "GET",
        url: `/api/v2/lists`,
        params: {
            _page: 1,
            _page_limit: 9999999999999999999999999999,
            _type: 2
        },
    })
}

async function getListDetailsApi(id:any){
    return axios({
        method: "GET",
        url: `/api/v2/list/${id}`,
        data: { id: id },
    })
}

async function getFullDetailsListApi(id:any){
    return axios({
        method: "GET",
        url: `/api/v2/list/hash/${id}`,
    })
}

async function putListDetailsNameApi(id:any, listName:string, listStatus:string) {
    return axios({
        method: "PUT",
        url: `/api/v2/list`,
        params: {
            _id: id,
            _list_name: listName,
            _list_status: listStatus
        },
    })
}

async function putChangeProductDetailsApi(data:SingleProductInterface) {
    return axios({
        method: "PUT",
        url: `/api/v2/list/${data.idList}/product/${data.id}`,
        data:{
            productName: data.productName,
            quantity: data.quantity,
            productStatus: data.productStatus,
            unit: data.unit,
            price: data.price,
            category: data.category_name
        },
    })
}

async function postAddNewProductApi(data:AddNewProductInterface) {
    return axios({
        method: "POST",
        url: `/api/v2/list/${data.idList}/product`,
        data:{
            productName: data.productName,
            quantity: data.quantity,
            productStatus: data.productStatus,
            unit: data.unit,
            price: data.price,
            category: data.category
        },
    })
}

async function postAddNewListApi(list_name:string) {
    return axios({
        method: "POST",
        url: `/api/v2/list`,
        data:{
            _list_name: list_name
        },
    })
}

async function putAddToArchivizeApi(id:string) {
    return axios({
        method: "PUT",
        url: `api/v2/list/${id}/archivize`,
        data:{
            id: id
        },
    })
}



async function deleteListApi(id:number) {
    return axios({
        method: "DELETE",
        url: `/api/v2/list`,
        data: { _id: id}
    })
}

async function putRestoreApi(id:number) {
    return axios({
        method: "PUT",
        url: `/api/v2/list/${id}/restore`,
        data: { id: id}
    })
}

async function getUsersOfSharedListApi(id:number) {
    return axios({
        method: "GET",
        url: `/api/v2/list/${id}/list_users`,
    })
}

async function postSendMailApi(id:number, email:string) {
    return axios({
        method: "POST",
        url: `/api/v2/list/${id}/send_mail`,
        params: {
            _email_address: email
        }
    })
}

async function putPermissionApi(id:number, params:PutPermissionsInterface) {
    return axios({
        method: "PUT",
        url: `/api/v2/shared_list/${id}`,
        params
    })
}

async function postShareToApi(params:AddSharedListInterface) {
    return axios({
        method: "POST",
        url: `/api/v2/list/share`,
        params
    })
}

async function deleteShareToApi(params:AddSharedListInterface) {
    return axios({
        method: "DELETE",
        url: `/api/v2/list/share`,
        params
    })
}


async function getReadListApi(id:string) {
    return axios({
        method: "GET",
        url: `/api/v2/list/hash/${id}`,
    })
}

async function deleteListProductApi(idProduct:number, idList:number) {
    return axios({
        method: "DELETE",
        url: `/api/v2/list/${idList}/product/${idProduct}`,
    })
}


// TEMPLATE ///////////////////
async function getPublicTemplatesApi() {
    return axios({
        method: "GET",
        url: `/api/v2/templates`,
        params: {
            _page: 1,
            _page_limit: 9999999999999999999999999999,
            _template_type: "szablon niestandardowy"
        }
    })
}

async function getPrivateTemplatesApi() {
    return axios({
        method: "GET",
        url: `/api/v2/my_templates`,
        params: {
            _page: 1,
            _page_limit: 9999999999999999999999999999,
        }
    })
}

async function getTemplateDetailsApi(id:any) {
    return axios({
        method: "GET",
        url: `/api/v2/template`,
        params: {
            _id: id
        },
    })
}

async function putEditTemplateProductApi(idProduct:number, idTemplate:number, data:EditProductTemplateInterface) {
    return axios({
        method: "PUT",
        url: `/api/v2/template/${idTemplate}/product/${idProduct}`,
        data
    })
}

async function deleteTemplateProductApi(idProduct:number, idTemplate:number) {
    return axios({
        method: "DELETE",
        url: `/api/v2/template/${idTemplate}/product/${idProduct}`,
    })
}

async function postAddToTemplateApi(data:AddNewTemplateInterface) {
    return axios({
        method: "POST",
        url: `/api/v2/template`,
        data
    })
}

async function postAddProductToTemplateApi(data:AddProductToTemplateInterface, id:number) {
    return axios({
        method: "POST",
        url: `/api/v2/template/${id}/product`,
        data
    })
}

async function deleteTemplateApi(id:number) {
    return axios({
        method: "DELETE",
        url: `/api/v2/template`,
        params: {
            _template_id: id
        }
    })
}

async function postAddTemplateToMyListApi(id:number, name:string) {
    return axios({
        method: "POST",
        url: `/api/v2/template/${id}/convert_to_list`,
        data: {
            list_name: name
        },
    })
}

async function postMergeTemplateWithListApi(idList:string, idTemplate:string) {
    return axios({
        method: "POST",
        url: `/api/v2/template/${idTemplate}/merge_with_list/${idList}`,
    })
}

//////////////////////// KEYWORDS
async function getKeywordProductApi(keyword:any){
    return axios({
        method: "GET",
        url: `/api/v2/product-keywords-search`,
        params: {
            query: keyword
        }
    })
}

async function getKeywordCategoryApi(){
    return axios({
        method: "GET",
        url: `/api/v2/category-keywords`,
    })
}

async function getKeywordCategorySearchApi(keyword:any){
    return axios({
        method: "GET",
        url: `/api/v2/category-keywords-search`,
        params: {
            query: keyword
        }
    })
}



export {
    postSendEmailApi,
    getListsApi,
    getArchiveListsApi,
    getSharedListsApi,
    getListDetailsApi,
    putListDetailsNameApi,
    putChangeProductDetailsApi,
    postAddNewProductApi,
    postAddNewListApi,
    putAddToArchivizeApi,
    postAddToTemplateApi,
    postAddProductToTemplateApi,
    deleteListApi,
    putRestoreApi,
    getUsersOfSharedListApi,
    postSendMailApi,
    putPermissionApi,
    postShareToApi,
    getReadListApi,
    getPrivateTemplatesApi,
    getPublicTemplatesApi,
    getTemplateDetailsApi,
    putEditTemplateProductApi,
    deleteTemplateProductApi,
    deleteListProductApi,
    deleteTemplateApi,
    postAddTemplateToMyListApi,
    getFullDetailsListApi,
    deleteShareToApi,
    getKeywordProductApi,
    getKeywordCategoryApi,
    getKeywordCategorySearchApi,
    postMergeTemplateWithListApi
}