import actions from "./actions"

const initialState = {
    lists: [],
    shared: [],
    archive: [],
    listDetails: {},
    templatePublic: [],
    templatePrivate: [],
    templateDetails: {},
    fullListDetails:{},
    keywordProduct:[],
    keywordCategory:[],
    keywordCategorySearch:[]
};

export default function vehicleReducers(state = initialState, action:{payload: any, type: any}):any{
    switch(action.type){
        case actions.SET_DATA:
            return {...state, lists: action.payload};
        case actions.SET_SHARED_DATA:
            return {...state, shared: action.payload};
        case actions.SET_ARCHIVE_DATA:
            return {...state, archive: action.payload};
        case actions.SET_LIST_DETAILS:
            return {...state, listDetails: action.payload};
        case actions.SET_FULL_LIST_DETAILS:
            return {...state, fullListDetails: action.payload};
        case actions.SET_INIT_LISTS:
            return {...state, lists:[], shared:[], archive:[], listDetails:{}, templatePublic:[], templatePrivate:[], templateDetails:{}};
        case actions.SET_INIT_LIST_DETAILS:
            return {...state, listDetails: {}};
        case actions.SET_PUBLIC_TEMPLATE:
            return {...state, templatePublic: action.payload};
        case actions.SET_INIT_PRIVATE_TEMPLATE:
            return {...state,templatePrivate: {}};
        case actions.SET_PRIVATE_TEMPLATE:
            return {...state, templatePrivate: action.payload};
        case actions.SET_TEMPLATE_DETAILS:
            return {...state, templateDetails: action.payload};
        case actions.SET_INIT_TEMPLATE_DETAILS:
            return {...state, templateDetails: {}};
        case actions.SET_KEYWORDS_PRODUCT:
            return {...state,keywordProduct: action.payload};
        case actions.SET_KEYWORDS_CATEGORY:
            return {...state,keywordCategory: action.payload};
        case actions.SET_KEYWORDS_CATEGORY_SEARCH:
            return {...state,keywordCategorySearch: action.payload};
        case actions.SET_INIT_CATEGORY_SEARCH:
            return {...state,keywordCategorySearch: null};
        default:
            return state;
    }
}