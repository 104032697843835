import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// import translate json
import template from './translations/pl/template.json';
import translation from './translations/pl/translation.json';
import menu from './translations/pl/menu.json';
import lists from './translations/pl/lists.json';
import users from './translations/pl/users.json';
import profile from './translations/pl/profile.json';
import lists_en from './translations/eng/lists.json';
import template_en from './translations/eng/template.json';
import menu_en from './translations/eng/menu.json';
import profile_en from './translations/eng/profile.json';
import translation_en from './translations/eng/translation.json';
import users_en from './translations/eng/users.json';
const resources = {
    pl: {
        template: {...template, ...menu},
        translation: translation,
        lists: lists,
        users,
        profile:profile
    },
    en: {
        lists: lists_en,
        template: {...template_en, ...menu_en},
        profile:profile_en,
        translation:translation_en,
        users_en
    }
};

const options = {
    order: ['localStorage', 'navigator', 'htmlTag'],
    lookupLocalStorage: 'language',
    caches: ['localStorage'],
    htmlTag: document.documentElement
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: options,
        resources,
        //lng: "pl",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
