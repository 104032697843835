import actions from './actions'

const initialState = {
    email: '',
    name: '',
    loading: false,
    authorized: false,
    city: '',
    imageName:'',
    status: "",
    surname: ""

};

export default function userReducer(state = initialState, action: { type: string; payload: any; }) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state
  }
}
