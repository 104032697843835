import { authClientId, authClientSecret } from "../config/config";
import {DataRegister, DataLogin, DataForgotPassword, DataResetPassword, EditPassword} from "./user.type";
import axios from 'axios';

// Create new user account.
function registerApi(data: DataRegister) {
    return axios({
        method: 'post',
        url: '/api/v2/profile',
        data: data
    });
}

// Request user credentials.
async function loginApi(data: DataLogin) {
    return axios({
        method: 'POST',
        url: '/oauth/v2/token',
        data: {
            ...data,
            grant_type: 'password',
            client_id: authClientId,
            client_secret: authClientSecret
        }
    });
}

// Show logged user details.
async function getMeInfoApi() {
    return axios({
        method: 'get',
        url: '/api/v2/me',
    });
}

// Logout user.
async function logoutApi() {
    return localStorage.removeItem("access_token");
    // return axios({
    //     method: 'DELETE',
    //     url: '/api/v1/logout'
    // });
}

// User forgot password reset link.
function forgotPasswordApi(data: DataForgotPassword) {
    return axios({
        method: 'POST',
        url: '/api/v2/profile/reset_password/step1',
        data
    });
}

// User reset password with link.
function resetPasswordApi(code: string, data: DataResetPassword) {
    return axios({
        method: 'PUT',
        url: `/api/v2/profile/reset_password/step2/${code}`,
        data
    });
}

// Update login user information.
function updateProfileDataApi(data: any) {
    return axios({
        method: 'POST',
        url: '/api/v2/me/update_profile',
        data: data
    });
}

// User activate account
function activateUserAccountApi(hash:string) {
    return axios({
        method: 'PUT',
        url: `/api/v2/me/activate/${hash}`,
    });
}

function changePasswordUserApi(data:EditPassword) {
    return axios({
        method: 'PUT',
        url: `/api/v2/profile/change-password`,
        data
    });
}

export {
    registerApi,
    loginApi,
    getMeInfoApi,
    logoutApi,
    forgotPasswordApi,
    resetPasswordApi,
    updateProfileDataApi,
    activateUserAccountApi,
    changePasswordUserApi
}
