const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  LOCAL_LOGOUT: 'user/LOCAL_LOGOUT',
  GET_DATA: "user/GET_DATA",
  LOCAL_ERROR:"user/LOCAL_ERROR",
  GO_LOGIN:"user/GO_LOGIN"
};

export default actions
