import {all, takeEvery, put, call} from 'redux-saga/effects';
import actions from "./actions"
import {
    getArchiveListsApi,
    getFullDetailsListApi,
    getKeywordCategoryApi,
    getKeywordCategorySearchApi,
    getKeywordProductApi,
    getListDetailsApi,
    getListsApi,
    getPrivateTemplatesApi,
    getPublicTemplatesApi,
    getSharedListsApi,
    getTemplateDetailsApi,
} from "../../services/lists.service"

export function* GET_LISTS(){
    try{
        const response = yield call(getListsApi);
        if (response.data){
            yield put({
                type: actions.SET_DATA,
                payload: response.data
            })
        }
    }
    catch (e) {}
}

export function* GET_SHARED_LISTS(){
    try{
        const response = yield call(getSharedListsApi);
        if (response.data){
            yield put({
                type: actions.SET_SHARED_DATA,
                payload: response.data
            })
        }
    }
    catch (e) {}
}

export function* GET_ARCHIVE_LISTS(){
    try{
        const response = yield call(getArchiveListsApi);
        if (response.data){
            yield put({
                type: actions.SET_ARCHIVE_DATA,
                payload: response.data
            })
        }
    }
    catch (e) {}
}

export function* GET_LIST_DETAILS(action:{payload: string, type: string}){
    try{
        const response = yield call(getListDetailsApi, action.payload);
        if (response.data){
            yield put({
                type: actions.SET_LIST_DETAILS,
                payload: response.data
            })
        }
    } catch (e) { }
}

export function* GET_FULL_LIST_DETAILS(action:{payload: string, type: string}){
    try{
        const response = yield call(getFullDetailsListApi, action.payload);
        if (response.data){
            yield put({
                type: actions.SET_FULL_LIST_DETAILS,
                payload: response.data
            })
        }
    } catch (e) { }
}


export function* GET_PUBLIC_TEMPLATE(){
    try{
        const response = yield call(getPublicTemplatesApi);
        if (response.data){
            yield put({
                type: actions.SET_PUBLIC_TEMPLATE,
                payload: response.data
            })
        }
    }
    catch (e) {}
}

export function* GET_PRIVATE_TEMPLATE(){
    try{
        const response = yield call(getPrivateTemplatesApi);
        if (response.data){
            yield put({
                type: actions.SET_PRIVATE_TEMPLATE,
                payload: response.data
            })
        }
    }
    catch (e) {}
}

export function* GET_TEMPLATE_DETAILS(action:{payload: string, type: string}){
    try{
        const response = yield call(getTemplateDetailsApi, action.payload);
        if (response.data){
            yield put({
                type: actions.SET_TEMPLATE_DETAILS,
                payload: response.data
            })
        }
    } catch (e) { }
}

export function* GET_KEYWORDS_PRODUCTS(action:{payload: string, type: string}){
    try{
        const response = yield call(getKeywordProductApi, action.payload);
        if (response.data){
            yield put({
                type: actions.SET_KEYWORDS_PRODUCT,
                payload: response.data
            })
        }
    } catch (e) { }
}

export function* GET_KEYWORDS_CATEGORY(){
    try{
        const response = yield call(getKeywordCategoryApi);
        if (response.data){
            yield put({
                type: actions.SET_KEYWORDS_CATEGORY,
                payload: response.data
            })
        }
    } catch (e) { }
}

export function* GET_KEYWORDS_CATEGORY_SEARCH(action:{payload: string, type: string}){
    try{
        const response = yield call(getKeywordCategorySearchApi, action.payload);
        if (response.data){
            yield put({
                type: actions.SET_KEYWORDS_CATEGORY_SEARCH,
                payload: response.data
            })
        }
    } catch (e) { }
}

export default function* RootSaga(){
    yield all([
        takeEvery(actions.GET_DATA, GET_LISTS),
        takeEvery(actions.GET_SHARED_DATA, GET_SHARED_LISTS),
        takeEvery(actions.GET_ARCHIVE_DATA, GET_ARCHIVE_LISTS),
        takeEvery(actions.GET_LIST_DETAILS, GET_LIST_DETAILS),
        takeEvery(actions.GET_PRIVATE_TEMPLATE, GET_PRIVATE_TEMPLATE),
        takeEvery(actions.GET_PUBLIC_TEMPLATE, GET_PUBLIC_TEMPLATE),
        takeEvery(actions.GET_TEMPLATE_DETAILS, GET_TEMPLATE_DETAILS),
        takeEvery(actions.GET_FULL_LIST_DETAILS, GET_FULL_LIST_DETAILS),
        takeEvery(actions.GET_KEYWORDS_PRODUCT, GET_KEYWORDS_PRODUCTS),
        takeEvery(actions.GET_KEYWORDS_CATEGORY, GET_KEYWORDS_CATEGORY),
        takeEvery(actions.GET_KEYWORDS_CATEGORY_SEARCH, GET_KEYWORDS_CATEGORY_SEARCH),
    ])
}