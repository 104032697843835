const actions = {
    GET_DATA: "lists/GET_DATA",
    SET_DATA: "lists/SET_DATA",

    GET_SHARED_DATA: "lists/GET_SHARED_DATA",
    SET_SHARED_DATA: "lists/SET_SHARED_DATA",

    GET_ARCHIVE_DATA: "lists/GET_ARCHIVE_DATA",
    SET_ARCHIVE_DATA: "lists/SET_ARCHIVE_DATA",

    GET_LIST_DETAILS: "lists/GET_LIST_DETAILS",
    SET_LIST_DETAILS: "lists/SET_LIST_DETAILS",


    GET_FULL_LIST_DETAILS: "lists/GET_FULL_LIST_DETAILS",
    SET_FULL_LIST_DETAILS: "lists/SET_FULL_LIST_DETAILS",

    SET_INIT_LISTS: "lists/SET_INIT_LISTS",
    SET_INIT_LIST_DETAILS: "lists/SET_INIT_LIST_DETAILS",
    SET_INIT_TEMPLATE_DETAILS:"lists/SET_INIT_TEMPLATE_DETAILS",

    GET_PUBLIC_TEMPLATE: "lists/GET_PUBLIC_TEMPLATE",
    SET_PUBLIC_TEMPLATE: "lists/SET_PUBLIC_TEMPLATE",
    SET_INIT_PRIVATE_TEMPLATE: "lists/SET_INIT_PRIVATE_TEMPLATE",

    GET_PRIVATE_TEMPLATE: "lists/GET_PRIVATE_TEMPLATE",
    SET_PRIVATE_TEMPLATE: "lists/SET_PRIVATE_TEMPLATE",

    GET_TEMPLATE_DETAILS: "lists/GET_TEMPLATE_DETAILS",
    SET_TEMPLATE_DETAILS: 'lists/SET_TEMPLATE_DETAILS',

    GET_KEYWORDS_PRODUCT: "lists/GET_KEYWORDS_PRODUCT",
    SET_KEYWORDS_PRODUCT: "lists/SET_KEYWORDS_PRODUCT",

    GET_KEYWORDS_CATEGORY: "lists/GET_KEYWORDS_CATEGORY",
    SET_KEYWORDS_CATEGORY: "lists/SET_KEYWORDS_CATEGORY",

    GET_KEYWORDS_CATEGORY_SEARCH: "lists/GET_KEYWORDS_CATEGORY_SEARCH",
    SET_KEYWORDS_CATEGORY_SEARCH: "lists/SET_KEYWORDS_CATEGORY_SEARCH",

    SET_INIT_CATEGORY_SEARCH: "lists/SET_INIT_CATEGORY_SEARCH",
};

export default actions;