import { all } from 'redux-saga/effects'
import settings from './settings/sagas'
import user from './user/sagas'
import menu from './menu/sagas'
import header from "./header/sagas";
import lists from "./lists/sagas";

export default function* rootSaga() {
    yield all([
        settings(),
        user(),
        menu(),
        header(),
        lists()
    ])
}
