import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import './i18n';
import axios from 'axios';
import { baseUrlApi } from "./config/config";
import {ConfigProvider, notification} from 'antd';
// import en_US from 'antd/es/locale/en_US'
import pl_PL from 'antd/es/locale/pl_PL'

// Redux
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import reducers from './redux/reducers';
import sagas from './redux/sagas';
import Router from './router';
import actions from "./redux/user/actions";

// middlewaredy
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)));
sagaMiddleware.run(sagas);


// Axios
axios.defaults.baseURL = baseUrlApi;
if (localStorage.getItem('access_token')) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
}
axios.interceptors.response.use(undefined, (error) => {
    if (error.request && error.request.status === 401) {
        let language = localStorage.getItem("language");
        if(language === "pl"){
            notification.error({
                message: "Błąd ładowania strony",
                description: "Strona nie została załadowana, ponieważ nie istnieje lub nie masz do niej dostępu."
            })
        } else {
            notification.error({
                message: "Page loading error",
                description: "The page was not loaded because it does not exist or you do not have access to it."
            })
        }
        store.dispatch({
            type: actions.LOCAL_LOGOUT
        });
    }
    return Promise.reject(error);
});

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={pl_PL}>
            <Router history={history} />
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
export { store, history }
