import React from 'react';
import { Route} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
// @ts-ignore
import Switch from 'react-router-transition-switch';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';

// import routers config
import {
    ROUTE_REGISTER,
    ROUTE_LOGIN,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_RESET_PASSWORD,
    ROUTE_LISTS,
    ROUTE_ARCHIVE_LIST,
    ROUTE_LIST_DETAILS,
    ROUTE_CREATE_LIST,
    ROUTE_PROFILE_EDIT,
    ROUTE_READ,
    ROUTE_TEMPLATE,
    ROUTE_TEMPLATE_DETAILS,
    ROUTE_TEMPLATE_CREATE,
    ROUTE_ABOUT_US,
    ROUTE_CONTACT,
    ROUTE_PRIVACY_POLICY,
    ROUTE_REGULATIONS,
    ROUTE_ERROR,
    ROUTE_HOME, ROUTE_ACTIVE, ROUTE_PROFILE,
} from "./config/routes";

// import layout
import Layout from "./layouts";

// import types
import { RouterTypeProps } from "./router.type";



// @ts-ignore
const loadable = loader => {
    return Loadable({
        loader,
        delay: false,
        loading: () => <></>,
    });
};

const routes = [
    {
        path: ROUTE_LISTS,
        Component: loadable(() => import('./pages/lists')),
        exact: true,
    },
    {
        path: ROUTE_REGISTER,
        Component: loadable(() => import('./pages/system/register')),
        exact: true,
    },
    {
        path: ROUTE_LOGIN,
        Component: loadable(() => import('./pages/system/login')),
        exact: true,
    },
    {
        path: ROUTE_READ,
        Component: loadable(() => import('./pages/system/read')),
        exact: true,
    },
    {
        path: ROUTE_FORGOT_PASSWORD,
        Component: loadable(() => import('./pages/system/forgot-password')),
        exact: true,
    },
    {
        path: ROUTE_RESET_PASSWORD,
        Component: loadable(() => import('./pages/system/reset-password')),
        exact: true,
    },
    {
        path: ROUTE_ARCHIVE_LIST,
        Component: loadable(() => import('./pages/lists/archive')),
        exact: true,
    },
    {
        path: ROUTE_LIST_DETAILS(),
        Component: loadable(() => import("./pages/lists/details")),
        exact:true
    },
    {
        path: ROUTE_CREATE_LIST,
        Component: loadable(() => import('./pages/lists/create')),
        exact: true,
    },
    {
        path: ROUTE_PROFILE_EDIT,
        Component: loadable(() => import('./pages/edit-profile')),
        exact: true,
    },
    {
        path: ROUTE_TEMPLATE,
        Component: loadable(() => import('./pages/template')),
        exact: true,
    },
    {
        path: ROUTE_TEMPLATE_DETAILS(),
        Component: loadable(() => import("./pages/template/details")),
        exact:true
    },
    {
        path: ROUTE_TEMPLATE_CREATE,
        Component: loadable(() => import("./pages/template/create")),
        exact:true
    },
    {
        path: ROUTE_ABOUT_US,
        Component: loadable(() => import("./components/system/Info/about_us")),
        exact:true
    },
    {
        path: ROUTE_CONTACT,
        Component: loadable(() => import("./components/system/Info/contact")),
        exact:true
    },
    {
        path: ROUTE_PRIVACY_POLICY,
        Component: loadable(() => import("./components/system/Info/privacy_policy")),
        exact:true
    },
    {
        path: ROUTE_REGULATIONS,
        Component: loadable(() => import("./components/system/Info/regulations")),
        exact:true
    },
    {
        path:ROUTE_ERROR,
        Component: loadable(() => import("./components/system/Errors/404")),
        exact:true
    },
    {
        path:ROUTE_HOME,
        Component: loadable(() => import("./components/system/Info/home")),
        exact:true
    },
    {
        path:ROUTE_ACTIVE(),
        Component: loadable(() => import("./components/system/Active")),
        exact:true
    },
    {
        path:ROUTE_PROFILE,
        Component: loadable(() => import("./pages/profile")),
        exact:true
    }
];

const mapStateToProps = (state: { settings: any; }) => ({ settings: state.settings });

class Router extends React.Component<RouterTypeProps, {}> {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            history,
            settings: { routerAnimation },
        } = this.props;
        return (
            <ConnectedRouter history={history}>
                <Layout>
                    <Switch
                        render={(props: { children: React.Component; location: { pathname: string; }; }) => {
                            const {
                                children,
                                location: { pathname },
                            } = props;
                            if(children === null) {
                                this.props.history.push(ROUTE_ERROR);
                                return(<></>)
                            } else {
                            return (
                                <SwitchTransition>
                                    <CSSTransition
                                        key={children === null ? "/error" : pathname}
                                        classNames={routerAnimation}
                                        timeout={routerAnimation === 'none' ? 0 : 300}
                                    >
                                        {children}
                                    </CSSTransition>
                                </SwitchTransition>
                            ) }
                        }}
                    >
                        {routes.map(({ path, Component, exact }) => {
                            return (
                            <Route path={path} key={path} exact={exact}>
                                <Component />
                            </Route>
                        )})}
                    </Switch>
                </Layout>
            </ConnectedRouter>
        );
    }
}

export default connect(mapStateToProps)(Router);
