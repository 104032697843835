import { all, put, takeEvery } from 'redux-saga/effects'
import actions from "./actions";

export function* SET_HEADER_TITLE(action: {payload: string, type: string}) {
    const { payload } = action;
    yield put({
        type: actions.SET_STATE,
        payload: {
            header: payload
        }
    })
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.SET_DATA, SET_HEADER_TITLE)
    ])
}
