import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'
import {ReducerType} from "../../../redux/reducer.type";
import { FooterPropsType } from "./footer.type";
import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
// import Text from "antd/es/typography/Text";
// import {List} from "antd";
// import facebook from "../../../assets/img/facebook.png";
class Footer extends React.Component<FooterPropsType, any> {

    render() {
        const {
            settings
        } = this.props;
        // const data = [
        //     {
        //         title: t("about_us_site"),
        //         route: "http://buyme-shopping.com/about_us.html"
        //     },
        //     {
        //         title: t("regulations_site"),
        //         route: "http://buyme-shopping.com/regulations.html"
        //     },
        //     {
        //         title: t("private_politic_site"),
        //         route: "http://buyme-shopping.com/privacy_policy.html"
        //     },
        //     {
        //         title: t("contact_site"),
        //         route: "http://buyme-shopping.com/contact.html"
        //     },
        // ];

        return (
            <div
                className={classNames(style.footer, {
                    [style.footerFullWidth]: settings.isContentNoMaxWidth,
                })}
            >
                <div className={style.inner}>
                    <div className="row m-0">
                        {/*<div className="d-none d-lg-block col-lg-2"/>*/}
                        {/*<div className="col-12 col-md-3 col-lg-2 mb-2">*/}
                        {/*    <div style={{borderLeft:'5px solid #FFC107'}}>*/}
                        {/*        <h3 style={{color:'white', marginLeft:'10px'}}>*/}
                        {/*            <i>*/}
                        {/*                 {t("about_buyme")}*/}
                        {/*            </i>*/}
                        {/*        </h3>*/}
                        {/*    </div>*/}
                        {/*    <Text style={{color:'#9f9f9f'}}>*/}
                        {/*        {t("about_buyme_description")}*/}
                        {/*        <a href="http://buyme-shopping.com/about_us.html" target="_blank" rel="noopener noreferrer">*/}
                        {/*            {t("read_more_with")}*/}
                        {/*        </a>*/}
                        {/*    </Text>*/}
                        {/*</div>*/}
                        {/*<div className="col-12 col-md-3 col-lg-2 mb-2">*/}
                        {/*    <div style={{borderLeft:'5px solid #FFC107'}}>*/}
                        {/*        <h3 style={{color:'white', marginLeft:'10px'}}>*/}
                        {/*            <i>*/}
                        {/*                {t("helpful_link")}*/}
                        {/*            </i>*/}
                        {/*        </h3>*/}
                        {/*    </div>*/}

                        {/*    <Text style={{color:'#9f9f9f'}}>*/}
                        {/*        <List*/}
                        {/*            itemLayout="horizontal"*/}
                        {/*            dataSource={data}*/}
                        {/*            style={{textAlign:'left'}}*/}
                        {/*            className="m-0 p-0"*/}
                        {/*            renderItem={item => (*/}
                        {/*                <List.Item onClick={() => {window.location.href = `${item.route}`}}>*/}
                        {/*                    <List.Item.Meta*/}
                        {/*                        title={*/}
                        {/*                            <a href={`${item.route}`} target="_blank" rel="noopener noreferrer">*/}
                        {/*                                {item.title}*/}
                        {/*                            </a> }*/}
                        {/*                    />*/}
                        {/*                </List.Item>*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*    </Text>*/}
                        {/*</div>*/}
                        {/*<div className="col-12 col-md-3 col-lg-2 mb-2">*/}
                        {/*    <div style={{borderLeft:'5px solid #FFC107'}}>*/}
                        {/*        <h3 style={{color:'white', marginLeft:'10px'}}>*/}
                        {/*            <i>*/}
                        {/*                {t("follow_us")}*/}
                        {/*            </i>*/}
                        {/*        </h3>*/}
                        {/*    </div>*/}
                        {/*    <Text style={{color:'#9f9f9f'}}>*/}
                        {/*        {t("follow_us_description")}*/}
                        {/*    </Text><br /><br />*/}
                        {/*    <a href="https://www.facebook.com/WEBimpuls/" target="_blank" rel="noopener noreferrer">*/}
                        {/*        <img src={facebook} style={{height:'25px', width:'25px'}} alt="facebook-icon"/>*/}
                        {/*    </a>*/}
                        {/*    <br /><br />*/}
                        {/*    <iframe*/}
                        {/*        src="https://www.facebook.com/plugins/share_button.php?href=http%3A%2F%2Fzakupy.wicms.pl%2F&layout=button_count&size=large&mobile_iframe=true&width=85&height=28&appId"*/}
                        {/*        width="150" height="28"*/}
                        {/*        scrolling="no" frameBorder="0"*/}
                        {/*        allow="encrypted-media"*/}
                        {/*        title="mapa"*/}
                        {/*    >*/}
                        {/*    </iframe>*/}
                        {/*</div>*/}
                        {/*<div className="col-12 col-md-3 col-lg-2 mb-2">*/}
                        {/*    <div style={{borderLeft:'5px solid #FFC107'}}>*/}
                        {/*        <h3 style={{color:'white', marginLeft:'10px'}}>*/}
                        {/*            <i>*/}
                        {/*                {t("about_company")}*/}
                        {/*            </i>*/}
                        {/*        </h3>*/}
                        {/*    </div>*/}
                        {/*    <Text style={{color:'#9f9f9f'}}>*/}
                        {/*        {t("about_company_description")}*/}
                        {/*        <a href="http://buyme-shopping.com/about_us.html" target="_blank" rel="noopener noreferrer">*/}
                        {/*            {t("read_more_with")}*/}
                        {/*        </a>*/}
                        {/*    </Text>*/}

                        {/*</div>*/}
                    {/*    <div className="col-12 mt-2">*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="d-none d-lg-block col-lg-2"/>*/}
                    {/*        <div className="col-6 col-lg-5 text-left mt-3">*/}
                    {/*           <span className={style.footerText}>*/}
                    {/*            Copyright & All right reserver by WEBimpuls 2018-2020*/}
                    {/*        </span>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-6 col-lg-5 text-center">*/}
                    {/*            <a href="http://www.webimpuls.pl" title="strony internetowe i pozycjonowanie" className={style.footerTextCopyright}>*/}
                    {/*                Projekt i realizacja <span className={style.footerTextWeb}>WEBimpuls.pl</span>*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                        <div className="col-12 col-lg-6 text-left mt-3 pl-5">
                            <span className={style.footerText}>
                                Copyright & All right reserver by WEBimpuls 2018-2020
                            </span>
                        </div>
                        <div className="col-12 col-lg-6 text-right pr-5">
                            <a href="http://www.webimpuls.pl" title="strony internetowe i pozycjonowanie" className={style.footerTextCopyright}>
                                Projekt i realizacja <span className={style.footerTextWeb}>WEBimpuls.pl</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: ReducerType) => ({settings: state.settings});

export default compose<FooterPropsType, any>(
    connect(mapStateToProps),
    withTranslation('template')
)(Footer);

