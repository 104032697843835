import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NProgress from 'nprogress';
import { Helmet } from 'react-helmet';
import Loader from "../components/layout/Loader/Loader";
import compose from 'recompose/compose';
import PublicLayout from "./Public";
import AppLayout from "./App";
import AuthLayout from "./Auth";
import ReadLayout from "./Read";
import InfoLayout from "./Info";
import ErrorLayout from "./Error";
import ActiveLayout from "./Active";
import {ReducerType} from "../redux/reducer.type";
import { LayoutPropsType, LayoutsPrevPropsType } from "./layout.type";
import { titlePrefix, titleDefult } from "../config/config";
import userActions from "../redux/user/actions";
import { withTranslation } from 'react-i18next';
import { history } from "../index";
import {ROUTE_HOME,} from "../config/routes";

const Layouts = {
    public: PublicLayout,
    auth: AuthLayout,
    app: AppLayout,
    read:ReadLayout,
    info: InfoLayout,
    error:ErrorLayout,
    activate:ActiveLayout
};

class Layout extends React.PureComponent<LayoutPropsType, any> {
    previousPath = '';

    componentDidMount(): void {
        const {
            dispatch,
            location: { pathname },
        } = this.props;
        if (localStorage.getItem('access_token')) {
            dispatch({
                type: userActions.LOAD_CURRENT_ACCOUNT
            });
        }
        if (pathname === '/') {
            history.push(ROUTE_HOME);
        }
    }

    componentDidUpdate(prevProps: Readonly<LayoutsPrevPropsType>, prevState: Readonly<{}>, snapshot?: any): void {
        const { location, user, dispatch, location: { pathname } } = this.props;
        const { prevLocation } = prevProps;
        if (location !== prevLocation) {
            window.scrollTo(0, 0)
        }
        if(pathname === "/" || pathname === ''){
            dispatch({
                type: userActions.GO_LOGIN,
            });
        } else {
            if (!user.authorized && !user.loading && !(/^\/auth(?=\/|$)/i.test(pathname)) && !(/^\/read(?=\/|$)/i.test(pathname)) && !(/^\/info(?=\/|$)/i.test(pathname)) && !(/^\/activate(?=\/|$)/i.test(pathname)) && !(/^\/error(?=\/|$)/i.test(pathname))) {
                if (localStorage.getItem("access_token")) {
                    return;
                } else {
                    console.log("src/layouts/index - LOGOUT - CHECK - POSSIBLY ISSUES");
                    dispatch({
                        type: userActions.LOCAL_LOGOUT,
                    });
                }
            }
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            children,
            location: { pathname, search },
            user,
            t,
        } = this.props;

        // NProgress Management
        const currentPath = pathname + search;
        if (currentPath !== this.previousPath) {
            NProgress.start()
        }

        setTimeout(() => {
            NProgress.done();
            this.previousPath = currentPath;
        }, 300);

        // Layout Rendering
        const getLayout = () => {
            if (pathname === '/') {
                return 'public'
            }
            if (/^\/auth(?=\/|$)/i.test(pathname)) {
                return 'auth'
            }
            if (/^\/read(?=\/|$)/i.test(pathname)) {
                return 'read'
            }
            if (/^\/info(?=\/|$)/i.test(pathname)) {
                return 'info'
            }
            if (/^\/error(?=\/|$)/i.test(pathname)) {
                return 'error'
            }
            if (/^\/activate(?=\/|$)/i.test(pathname)) {
                return 'activate'
            }
            return 'app'
        };

        const Container: any = Layouts[getLayout()];
        const isUserAuthorized = user.authorized;
        const isUserLoading = user.loading;
        const isAuthLayout = getLayout() === 'auth';

        const BootstrappedLayout = () => {
            // show loader when user in check authorization process, not authorized yet and not on login pages
            if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
                return <Loader spinning={true} fullScreen={true} />
            }
            // in other case render previously set layout
            return <Container>{children}</Container>
        };

        return (
            <>
                <Helmet titleTemplate={`${t(titlePrefix)}! Home shopping is easy | %s`} title={t(titleDefult)} />
                {BootstrappedLayout()}
            </>
        );
    }
}

const mapStateToProps = (state: ReducerType) => ({user: state.user});

export default withRouter(compose<LayoutPropsType, any>(
    connect(mapStateToProps),
    withTranslation('template')
)(Layout)) as React.ComponentClass<{}>;
