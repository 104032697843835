import React from "react";
import style from './style.module.scss'
import UserMenu from './UserMenu'
import { connect } from 'react-redux';
import {ReducerType} from "../../../redux/reducer.type";
import {TopbarTypeProps, TopBarTypeState} from "./topbar.type";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {withRouter} from "react-router-dom";
import {Tooltip} from "antd";
import compose from "recompose/compose";
import {withTranslation} from "react-i18next";
import {createNewList} from "../../../utils/utils";
import {ROUTE_LIST_DETAILS, ROUTE_TEMPLATE_CREATE} from "../../../config/routes";


class TopBar extends React.Component<TopbarTypeProps, TopBarTypeState> {
    constructor(props:TopbarTypeProps) {
        super(props);
        this.state = {
            block:true
        }
    }
    newList = () => {
        this.setState({block:false});
        createNewList().then((res:any) => {
            if(res) this.props.history.push(ROUTE_LIST_DETAILS(String(res)));
            this.setState({block:true})
        });
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const { header, location} = this.props;
        const { pathname } = location;
        // @ts-ignore
        return (
            <div className={style.topbar}>
                <div className={style.topbar_left}>
                    <h3 className={style.topbar_header}>{header.header}
                    {
                        ((String(pathname) === "/lists") || (String(pathname) === '/template')) &&
                            <Tooltip placement="top" title={String(pathname) === "/lists" ? this.props.t("createList") : this.props.t("createNewTemplate")}>
                                <FontAwesomeIcon icon={faPlusCircle} className={style.plus} color={"white"} onClick={() => {String(pathname) === "/lists" ? this.state.block && this.newList() : this.props.history.push(ROUTE_TEMPLATE_CREATE)}}/>
                            </Tooltip>
                    }
                    </h3>
                </div>
                <div className={style.topbar_right}>
                    <UserMenu />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: ReducerType) => ({
    header: state.header,
    user: state.user
});

export default withRouter(compose<TopbarTypeProps, any>(
    connect(mapStateToProps),
    withTranslation('lists')
)(TopBar));
