export const ROUTE_LISTS = '/lists';
export const ROUTE_ARCHIVE_LIST ='/archive';
export const ROUTE_LIST_DETAILS = (id = ":id") =>  `/lists/details/${id}`;
export const ROUTE_CREATE_LIST ='/lists/create';
export const ROUTE_TEMPLATE = '/template';
export const ROUTE_TEMPLATE_CREATE = '/template/create';
export const ROUTE_TEMPLATE_DETAILS = (id = ":id") =>  `/template/details/${id}`;

// Auth
export const ROUTE_REGISTER = '/auth/register';
export const ROUTE_LOGIN = '/auth/login';
export const ROUTE_FORGOT_PASSWORD = '/auth/forgot-password';
export const ROUTE_RESET_PASSWORD = '/auth/reset-password/:id';

// Public
export const ROUTE_READ ='/read/:id';

// Profile
export const ROUTE_PROFILE_EDIT = '/edit-profile';
export const ROUTE_PROFILE = '/profile';

// Info
export const ROUTE_ABOUT_US = '/info/about_us';
export const ROUTE_CONTACT = '/info/contact';
export const ROUTE_PRIVACY_POLICY = '/info/privacy_policy';
export const ROUTE_REGULATIONS = '/info/regulations';

// Error
export const ROUTE_ERROR ='/error';

// HOME PAGE
export const ROUTE_HOME ='/';

// ACTIVE
export const ROUTE_ACTIVE = (id = ":id") =>  `/activate/${id}`;
