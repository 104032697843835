import React from "react";

class PublicLayout extends React.Component<React.Component, any> {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const { children } = this.props;
        return (
            <>
                {children}
            </>
        );
    }
}

export default PublicLayout;
