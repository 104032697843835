import React from "react";
import { Layout } from 'antd'

class AuthLayout extends React.Component<React.Component, any> {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const { children } = this.props;
        return (
            <Layout>
                <Layout.Content>
                    {children}
                </Layout.Content>
            </Layout>
        );
    }
}

export default AuthLayout;
