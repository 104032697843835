import { TFunction } from "i18next";
import moment from 'moment';
import {postAddNewListApi} from "../services/lists.service";
import Moment from 'moment';
/**
 * Convert API errors to form fields.
 *
 * @param t
 * @param values
 * @param errors
 */
export const convertApiErrorToForm = (t: TFunction, values: any, errors: any) => {
    let fields: any = {};
    const keys = Object.keys(values);

    for (let i = 0; i < keys.length; i++) {
        const fromApi: any = errors[keys[i]];

        if (typeof fromApi !== 'undefined') {
            let value = values[keys[i]];

            if (keys[i].indexOf('date') !== -1 || keys[i].indexOf('expire') !== -1) {
                value = moment(values[keys[i]])
            }

            fields[keys[i]] = {
                value: value
            };

            if (fromApi.hasOwnProperty('errors')) {
                fields[keys[i]].errors = fromApi.errors.map((value: any) => {
                    return new Error(t(value));
                });
            }
        }
    }

    return fields;
};

/**
 * Check object is empty.
 *
 * @param obj
 */
export const isEmpty = (obj: object) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * Create new list with default name + data.
 *
 */
export const createNewList = () => {
    let language = localStorage.getItem("language");
    let dat = new Date();
    let data = Moment(dat).format('DD-MM-YYYY');
    let text;
    if(language === 'pl') {
        text = `Lista zakupów z dnia ${data}`
    } else {
        text = `Shopping list of ${data}`
    }
    return postAddNewListApi(text)
        .then((res:any) => {
            let id = res.data.id;
            if(id !== 0) return id; else return false
        })
        .catch(() => {
        });

};
