import React from 'react'
import { connect } from 'react-redux'
import {Menu, Dropdown, Avatar} from 'antd'
import styles from './style.module.scss'
import { ReducerType } from "../../../../redux/reducer.type";
import { MenuUserPropsType, MenuUserStateType } from "./menuUser.type";
import actions from "../../../../redux/user/actions";
import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next';
import {faSignOutAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ROUTE_PROFILE} from "../../../../config/routes";
import { Link } from "react-router-dom";
import {languages} from "../../../../config/config";
import { Select } from 'antd';
import i18n from '../../../../i18n';
import {Flag} from "semantic-ui-react";
import '../../../../assets/styles/flags/flag.css';
import noname from "../../../../assets/img/noname.png";
import {updateProfileDataApi} from "../../../../services/user.service";

const { Option } = Select;

class ProfileMenu extends React.Component<MenuUserPropsType, MenuUserStateType> {
    state = {
        visible: false,
        reload:false
    };
    handleVisibleChange = (flag: boolean) => {
        this.setState({ visible: flag });
    };
  logout = () => {
    const { dispatch } = this.props;
    dispatch({
      type: actions.LOCAL_LOGOUT,
    })
  };

    changeLanguage = (value: string) => {
        const { user,dispatch } = this.props;
        if(user.status === "A"){
            let formData = new FormData();
            formData.append("img", user.imageName);
            formData.append("name", user.name);
            formData.append("surname", user.surname);
            formData.append("city", user.city);
            formData.append("language", value);
            updateProfileDataApi(formData).then(() => {
                dispatch({
                    type: actions.LOAD_CURRENT_ACCOUNT
                });
            });
        } else {
            localStorage.setItem('language', value);
            i18n.changeLanguage(value);
            window.location.reload();
        }

    };

    componentDidUpdate(prevProps: Readonly<MenuUserPropsType>, prevState: Readonly<MenuUserStateType>, snapshot?: any): void {
        const { user } = this.props;
        if(user && user.language && (i18n.language !== user.language) && user.status === "A"){
            localStorage.setItem('language', user.language);
            i18n.changeLanguage(user.language);
            window.location.reload();
        }
    }

    render() {
    const { user, t } = this.props;
      if(i18n.language === 'pl-PL') {
          localStorage.setItem('language', 'pl');
          i18n.changeLanguage('pl');
      }
    const menu = (
      <Menu
          selectable={false}
          style={{minWidth: 250}}
      >
          {
              user.status === "N"
          }
        <Menu.Item>
          <strong>{user.status === "N" ? t("account_no_activate") : `${t('user_menu_prefix_user')}, ${user.name} ${user.surname}`}</strong>
        </Menu.Item>
          {user.status === "A" && <Menu.Divider />}
          {user.status === "A" &&
              <Menu.Item>
                  <strong>E-mail:</strong> {user.email} <br />
                  <strong>{t("city")}:</strong> {user.city} <br />
              </Menu.Item>
          }
          {user.status === "A" && <Menu.Divider />}
          <Menu.Item>
              <Link to={ROUTE_PROFILE} >
                  <FontAwesomeIcon icon={faUser} className={styles.menuIcon} />
                  {t('menu_user_edit_profile')}
              </Link>
          </Menu.Item>
          <Menu.Item>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <span style={{marginLeft: 16}}>{t('menu_user_change_language')}</span>
                  <Select defaultValue={i18n.language} size="small" onChange={this.changeLanguage}>
                      {languages.map(el => (
                          <Option key={el.i18n} value={el.i18n}>
                              {el.name === "PL" ? <Flag name={'pl'}/> : <Flag name={'us'}/>}
                          </Option>
                      ))}
                  </Select>
              </div>
          </Menu.Item>
          <Menu.Divider />
        <Menu.Item onClick={this.logout}>
          <button type="button" className="linkButton" >
            <FontAwesomeIcon icon={faSignOutAlt} className={styles.menuIcon} />
              {t('user_menu_logout')}
          </button>
        </Menu.Item>
      </Menu>
    );
    return (
        <>
        {
            this.props.user && this.props.user.email !== '' &&
            <Dropdown
                overlay={menu}
                trigger={['click']}
                onVisibleChange={this.handleVisibleChange}
                visible={this.state.visible}
            >
                <div className={styles.dropdown}>
                    <Avatar src={this.props.user.imageName ? `http://api.buyme-shopping.com/uploads/avatars/${this.props.user.imageName}` : noname } className={styles.avatar} shape="square" size="large" icon="user" />
                </div>
            </Dropdown>
        }
        </>
    )
  }
}

const mapStateToProps = (state: ReducerType) => ({ user: state.user });

export default compose<MenuUserPropsType, {}>(
    connect(mapStateToProps),
    withTranslation('template')
)(ProfileMenu)
