import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import settings from './settings/reducers';
import user from './user/reducers';
import menu from './menu/reducers';
import header from './header/reducers';
import lists from "./lists/reducers";
import { History } from 'history';

export default (history: History) => combineReducers({
    router: connectRouter(history),
    settings,
    user,
    menu,
    header,
    lists
});
