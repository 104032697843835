import React from "react";
import classNames from "classnames";
import styles from './style.module.scss';

// import Type
import LoaderType from "./loader.type";

const Loader = ({ spinning = true, fullScreen }: LoaderType) => (
    <div
        className={classNames(styles.loader, {
            [styles.hidden]: !spinning,
            [styles.fullScreen]: fullScreen
        })}
    />
);

export default Loader;
