import React from "react";
import classNames from 'classnames'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Layout } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import style from './style.module.scss'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import compose from 'recompose/compose';
import { MenuleftPropsType, MenuLeftStateType } from "./menuleft.type";
import {ReducerType} from "../../../redux/reducer.type";
import _ from 'lodash';
import logo from '../../../assets/img/logo.png';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { Sider } = Layout;

class MenuLeft extends React.Component<MenuleftPropsType, MenuLeftStateType> {
    constructor(props: MenuleftPropsType) {
        super(props);
        this.state = {
            activeSubmenu: '',
            activeItem: '',
            renderedFlyoutItems: {}
        }
    }

    flyoutTimers: any = {};

    currentLocation: string = '';

    componentDidMount() {
        this.setActiveItems(this.props)
    }

    componentWillReceiveProps(newProps: any) {
        const { pathname } = newProps.location;
        if (this.currentLocation !== pathname) {
            this.setActiveItems(newProps);
            this.currentLocation = pathname
        }
    }

    toggleMenu = (e: any) => {
        e.preventDefault();
        const { dispatch, settings } = this.props;
        const { isMenuCollapsed } = settings;
        dispatch({
            type: 'settings/CHANGE_SETTING',
            payload: {
                setting: 'isMenuCollapsed',
                value: !isMenuCollapsed,
            },
        })
    };

    toggleMobileMenu = (e: any) => {
        e.preventDefault();
        const { dispatch, settings } = this.props;
        const { isMobileMenuOpen } = settings;
        dispatch({
            type: 'settings/CHANGE_SETTING',
            payload: {
                setting: 'isMobileMenuOpen',
                value: !isMobileMenuOpen,
            },
        })
    };

    handleSubmenuClick = (e: any, key: any) => {
        e.preventDefault();
        const { activeSubmenu } = this.state;
        const { flyoutActive } = this.props;
        if (flyoutActive) {
            return
        }
        this.setState({
            activeSubmenu: activeSubmenu === key ? '' : key
        })
    };

    handleFlyoutOver = (event: any, key: string, items: any) => {
        const { flyoutActive } = this.props;
        if (flyoutActive) {
            clearInterval(this.flyoutTimers[key]);
            const item = event.currentTarget;
            const itemDimensions = item.getBoundingClientRect();
            const element = this.renderFlyoutMenu(items, key, itemDimensions);
            this.setState(state => ({
                renderedFlyoutItems: {
                    ...state.renderedFlyoutItems,
                    [key]: element,
                },
            }))
        }
    };

    handleFlyoutOut = (key: any) => {
        const { flyoutActive } = this.props;
        if (flyoutActive) {
            this.flyoutTimers[key] = setTimeout(() => {
                this.setState(state => {
                    delete state.renderedFlyoutItems[key];
                    return {
                        renderedFlyoutItems: {
                            ...state.renderedFlyoutItems,
                        },
                    }
                })
            }, 100)
        }
    };

    handleFlyoutContainerOver = (key: any) => {
        clearInterval(this.flyoutTimers[key])
    };

    renderFlyoutMenu = (items: any, key: any, itemDimensions: any) => {
        const { settings, t } = this.props;
        const { activeItem } = this.state;
        const left = `${itemDimensions.left + itemDimensions.width - 10}px`;
        const top = `${itemDimensions.top}px`;

        return (
            <div
                style={{ left, top }}
                className={classNames(style.air__menuFlyout, {
                    [style.air__menuFlyoutLeft]: settings.menuLayoutType === 'left',
                    [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'dark',
                    [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white',
                    [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray',
                })}
                key={key}
            >
                <ul
                    className={style.air__menuLeft__list}
                    onMouseEnter={() => this.handleFlyoutContainerOver(key)}
                    onMouseLeave={() => this.handleFlyoutOut(key)}
                >
                    {items.map((item: any) => {
                        return (
                            <li
                                className={classNames(style.air__menuLeft__item, {
                                    [style.air__menuLeft__item__active]: activeItem === item.key,
                                })}
                                key={item.key}
                            >
                                <Link to={item.url} className={style.air__menuLeft__link}>
                                    {item.icon && <FontAwesomeIcon icon={item.icon}/>}
                                    <span>{t(item.title)}</span>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    };

    setActiveItems = (props: any) => {
        const { menuData = [] } = props;
        if (!menuData.length) {
            return
        }
        const flattenItems = (items: any, key: any) =>
            items.reduce((flattenedItems: any, item: any) => {
                flattenedItems.push(item);
                if (Array.isArray(item[key])) {
                    return flattenedItems.concat(flattenItems(item[key], key))
                }
                return flattenedItems
            }, []);
        const activeItem = _.find(flattenItems(menuData, 'children'), ['url', props.location.pathname]);
        const activeSubmenu = menuData.reduce((key: any, parent: any) => {
            if (Array.isArray(parent.children) && activeItem !== undefined) {
                parent.children.map((child: any) => {
                    if (child.key === activeItem.key) {
                        key = parent
                    }
                    return ''
                })
            }
            return key
        });
        if (activeItem !== undefined) {
            this.setState({
                activeItem: activeItem.key,
                activeSubmenu: activeSubmenu.key,
            })
        }
    };

    generateMenuItems = () => {
        const { menuData = [], t } = this.props;
        const { activeSubmenu, activeItem } = this.state;

        const menuItem = (item: any) => {
            const { key, title, icon, url } = item;
            if (item.category) {
                return (
                    <li className={style.air__menuLeft__category} key={Math.random()}>
                        <span>{t(title)}</span>
                    </li>
                )
            }
            return (
                <li
                    className={classNames(style.air__menuLeft__item, {
                        [style.air__menuLeft__item__active]: activeItem === key,
                    })}
                    key={key}
                >
                    {item.url && (
                        <Link to={url} className={style.air__menuLeft__link}>
                            {icon && <FontAwesomeIcon icon={icon} />}
                            <span>{t(title)}</span>
                        </Link>
                    )}
                    {!item.url && (
                        <button type="button" onClick={(e) => (e.preventDefault())} className={"linkButton "+style.air__menuLeft__link}>
                            {icon && <FontAwesomeIcon icon={icon} />}
                            <span>{t(title)}</span>
                        </button>
                    )}
                </li>
            )
        };

        const submenuItem = (item: any) => {
            const { t } = this.props;
            return (
                <li
                    className={classNames(style.air__menuLeft__item, style.air__menuLeft__submenu, {
                        [style.air__menuLeft__submenu__active]: activeSubmenu === item.key,
                    })}
                    key={item.key}
                >
                    <button
                        type="button"
                        className={"linkButton "+style.air__menuLeft__link}
                        onClick={(e) => this.handleSubmenuClick(e, item.key)}
                        onMouseEnter={event => this.handleFlyoutOver(event, item.key, item.children)}
                        onFocus={event => this.handleFlyoutOver(event, item.key, item.children)}
                        onMouseLeave={() => this.handleFlyoutOut(item.key)}
                        onBlur={() => this.handleFlyoutOut(item.key)}
                    >
                        {item.icon && <FontAwesomeIcon icon={item.icon} />}
                        <span>{t(item.title)}</span>
                        {item.count && (
                            <span className="badge text-white bg-blue-light float-right mt-1 px-2">
                {item.count}
              </span>
                        )}
                    </button>
                    <ul className={style.air__menuLeft__list}>
                        {item.children.map((sub: any) => {
                            if (sub.children) {
                                return submenuItem(sub)
                            }
                            return menuItem(sub)
                        })}
                    </ul>
                </li>
            )
        };

       return menuData.filter((item:any) => item.both || !item.admin).map((item: any) => {
                if (item.children) {
                return submenuItem(item)
            }
            return menuItem(item)
        })
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const { settings } = this.props;
        const { renderedFlyoutItems } = this.state;
        const items = this.generateMenuItems();
        return (
            <Sider width="auto">
                <TransitionGroup>
                    {Object.keys(renderedFlyoutItems).map(item => {
                        return (
                            <CSSTransition key={item} timeout={0} classNames="air__menuFlyout__animation">
                                {renderedFlyoutItems[item]}
                            </CSSTransition>
                        )
                    })}
                </TransitionGroup>
                <div
                    className={classNames(style.air__menuLeft, {
                        [style.air__menuLeft__mobileToggled]: settings.isMobileMenuOpen,
                        [style.air__menuLeft__toggled]: settings.isMenuCollapsed,
                        [style.air__menuLeft__unfixed]: settings.isMenuUnfixed,
                        [style.air__menuLeft__shadow]: settings.isMenuShadow,
                        [style.air__menuLeft__flyout]: settings.menuType === 'flyout',
                        [style.air__menuLeft__compact]: settings.menuType === 'compact',
                        [style.air__menuLeft__blue]: settings.menuColor === 'blue',
                        [style.air__menuLeft__white]: settings.menuColor === 'white',
                        [style.air__menuLeft__gray]: settings.menuColor === 'gray',
                        [style.air__menuFlyout__black]:
                        settings.flyoutMenuColor === 'dark' && settings.menuType !== 'default',
                        [style.air__menuFlyout__white]:
                        settings.flyoutMenuColor === 'white' && settings.menuType !== 'default',
                        [style.air__menuFlyout__gray]:
                        settings.flyoutMenuColor === 'gray' && settings.menuType !== 'default',
                    })}
                >
                    <div className={style.air__menuLeft__outer}>
                        <button
                            type="button"
                            className={"linkButton "+style.air__menuLeft__mobileToggleButton}
                            onClick={this.toggleMobileMenu}
                        >
                            <span />
                        </button>
                        <button
                            type="button"
                            className={"linkButton "+style.air__menuLeft__toggleButton}
                            onClick={this.toggleMenu}
                        >
                            <span />
                            <span />
                        </button>
                        <button type="button" onClick={e => e.preventDefault()} className={"linkButton "+style.air__menuLeft__logo}>
                            <img src={logo} className={style.air__menuLeft__logo__img} alt="logo" />
                            {/*<div className={style.air__menuLeft__logo__name}>{t('Menu')}</div>*/}
                        </button>
                        <Scrollbars
                            autoHide
                            renderThumbVertical={({ ...props }) => (
                                <div
                                    {...props}
                                    style={{
                                        width: '5px',
                                        borderRadius: 'inherit',
                                        backgroundColor: 'rgba(195, 190, 220, 0.4)',
                                        left: '1px',
                                    }}
                                />
                            )}
                        >
                            <div id="menu-left-container" className={style.air__menuLeft__container}>
                                <ul className={style.air__menuLeft__list}>
                                    {items}
                                </ul>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
                <button
                    type="button"
                    className={"linkButton "+style.air__menuLeft__backdrop}
                    onClick={this.toggleMobileMenu}
                />
            </Sider>
        );
    }
}

const mapStateToProps = (state: ReducerType) => ({
    settings: state.settings,
    menuData: state.menu.menuData,
    user: state.user,
    flyoutActive: (state.settings.menuType === 'flyout' ||
        state.settings.menuType === 'compact' ||
        state.settings.isMenuCollapsed) &&
        !state.settings.isMobileView
});

export default withRouter(compose<MenuleftPropsType, any>(
    connect(mapStateToProps),
    withTranslation('template')
)(MenuLeft));
